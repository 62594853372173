.fundraiser-container {
    /* display: flex; */
    /* flex-direction: column;
  align-items: center; */
    gap: 1rem;
    padding: 2rem;
}

.question-answer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    border-radius: 10px;
    border: 1px solid #ccc;
    box-shadow: 0px 5px 16px -3px #00000033;
}


/* Creating the second css */

.fundraiser-container-contents {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    margin: auto;
    /* justify-content: center; */
    align-items: center;
}

.container-title {
    color: #3A5839;
    font-family: Inter;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.fundraiser-content {
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 2rem;
    width: 100%;
}

.fundraiser-left-content {
    flex: 1;
    border-radius: 1.46475rem;
    background: #FFF;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
    padding: 1rem;
    max-width: 60%;
}

.container-form-controller {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
}

.fundraiser-right-content {
    border-radius: 1.46475rem;
    background: #FFF;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
    flex: 1;
    max-width: 40%;
    max-height: 420px;
}

.upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1rem 2rem;
    flex: 1;
    height: 13rem;
    flex-shrink: 0;
    border-radius: 1.46475rem 1.46475rem 0rem 0rem;
    background: #E4F4E3;
}

.upload-container img {
    width: 5rem;
    height: 5rem;
    object-fit: contain;
}

.upload-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px dashed #ccc;
    width: 100%;
    height: 100%;
}

.goal-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 2rem;
    flex: 1;
}

.submit-button {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 2rem;
    flex: 1;
    margin-top: auto;
}

.QnA-chip {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@100;200;300;400;500;600;700;800;900&display=swap');
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    font-family: 'Manrope', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.circle-image {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f1f1f1;
}

.navbar .logo {
    margin-right: auto;
}

:root {
    --main-green: #648463;
    --light-blue: #1a73e8;
    --dark-blue: #37639e;
    --grey: #f6f4f6;
}

* {
    margin: 0;
    padding: 0;
}

body {
    font-family: "Inter", sans-serif;
}

.navbar {
    background: white;
    border-bottom: 1px solid #dbd9db;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar>ul {
    display: flex;
    justify-content: space-around;
}

.navbar>ul>li {
    color: white;
    list-style-type: none;
    font-size: 15px;
    padding: 5px 20px;
}

.navbar-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    font-weight: bold;
    width: 200px;
}

.navbar-button {
    border: 2px solid white;
    margin: 0px 20px;
    border-radius: 10px;
    font-weight: bold;
}

.footer {
    background: var(--grey);
    display: flex;
    justify-content: center;
    bottom: 0;
    flex-direction: column;
}

.footer-heading {
    font-weight: bold;
    color: #737273;
    font-size: 13px;
}

.all-footers {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    padding-top: 35px;
    min-width: 1000px;
    justify-content: space-between;
}

.footer-sub-heading {
    list-style-type: none;
    margin-top: 5px;
    font-size: 16px;
}

.divider {
    border-bottom: 1px solid #dbd9db;
    width: 1000px;
    margin: 10px auto 10px auto;
}

.trademarks {
    display: flex;
    padding: 10px 10px;
    justify-content: space-between;
    min-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.copyrights {
    color: #737273;
    font-size: 12px;
    margin: 10px 0px;
}

.language-button {
    padding: 10px 15px;
    height: 40px;
    border-radius: 5px;
    background: #EFEFEF;
    border: 1px solid #dbd9db;
}

.login-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.login-content {
    margin: auto;
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 15px;
    width: 300px;
}

.login-heading {
    font-weight: bold;
    margin: 10px 0px;
    font-size: 24px;
}

.sign-up-link {
    color: var(--main-green);
    text-decoration: underline;
}

.login-input-fields {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.login-input-top {
    border-radius: 5px 5px 0px 0px;
    border: 1px solid var(--main-green);
    background: #fff;
    padding: 10px;
    border-bottom: none;
}

.login-input-bottom {
    border-radius: 0px 0px 5px 5px;
    border: 1px solid var(--main-green);
    background: #fff;
    padding: 10px;
}

.forgot-password-section {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    padding: 10px 0px;
}

.forgot-password-text {
    color: var(--main-green);
}

.login-button {
    padding: 15px 125px;
    border-radius: 5px;
    background-color: var(--main-green);
}

.login-button-text {
    font-size: 15px;
    font-weight: bold;
    color: white;
}

.login-legal-conditions {
    margin-top: 10px;
    font-size: 12px;
    line-height: 20px;
}

.terms-of-service {
    text-decoration: underline;
}

.privacy-policy {
    text-decoration: underline;
}

.navbar-lite-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row !important;
    width: 100%;
    max-width: min(1400px, 100%);
}

.navbar-lite-container .navbar-list {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-direction: row;
}

.navbar-lite-container .logo {
    position: relative;
}

.navbar-lite-container .logo:hover {
    cursor: pointer;
    transition: .3s all;
}

.navbar-list-container .logo svg path {
    transition: .3s all;
}

.navbar-lite-container .logo:hover svg path {
    fill: var(--main-green);
}

.logo-extra-content {
    position: absolute;
    top: 50%;
    left: calc(100% + 10px);
    width: 150px;
    height: 50px;
    transform: translateY(-50%);
    border: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    gap: 10px;
    border-radius: 10px;
    opacity: 0;
    animation: logoFadeIn .3s ease-in-out forwards;
    animation-delay: 15s;
}

@keyframes logoFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.logo-extra-content::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -10px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #ccc;
    transform: translateY(-50%);
}

.logo-extra-content .cross-button {
    font-size: 24px;
    display: flex;
    align-items: center;
    padding-bottom: 3px;
}
    .section-1 {
        display: flex;
        gap: 40px;
    }
    
    .section-1 .left-image {
        max-width: 589px;
        max-height: 515px;
    }
    
    .section-1 .left-image img {
        height: 100%;
        width: 100%;
        border-radius: 24px;
        object-fit: cover;
    }
    
    .section-1 .right-part {
        display: flex;
        flex-direction: column;
        gap: 20px;
        flex: 1;
        justify-content: center;
    }
    
    .bg-colour-custom {
        background-color: #111118;
    }
    
    .hero {
        -webkit-text-size-adjust: 100%;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeSpeed;
        color: #000;
        font-size: 1rem;
        line-height: 1.5rem;
        font-family: "Inter", Helvetica, sans-serif;
        box-sizing: inherit;
        position: relative;
        display: inline-block;
        width: 100%;
        padding: 5rem;
        padding-top: 5rem;
        z-index: 100;
    }
    
    .max-width-lg {
        -webkit-text-size-adjust: 100%;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeSpeed;
        color: #000;
        font-size: 1rem;
        line-height: 1.5rem;
        font-family: "Inter", Helvetica, sans-serif;
        box-sizing: inherit;
        margin: 0 auto;
        width: 100%;
        max-width: 1280px;
    }
    
    section.hero.padding.hero-media>div>div.align-center.max-width-sm.space-bottom.space-hero.space-top {
        -webkit-text-size-adjust: 100%;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeSpeed;
        color: #000;
        font-size: 1rem;
        line-height: 1.5rem;
        font-family: "Inter", Helvetica, sans-serif;
        box-sizing: inherit;
        text-align: center;
        margin: 0 auto;
        width: 100%;
        max-width: 680px;
        margin-bottom: 5rem;
        margin-top: 5rem;
    }
    
    .title-h1,
    .section-2-title {
        -webkit-text-size-adjust: 100%;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeSpeed;
        color: #000;
        text-align: center;
        box-sizing: inherit;
        margin: 0 0 0.5em 0;
        font-family: "Inter", Helvetica, sans-serif;
        font-size: 3rem;
        font-weight: 700;
        line-height: 4rem;
        margin-top: 0;
    }
    
    section.hero.padding.hero-media>div>div.align-center.max-width-sm.space-bottom.space-hero.space-top>div {
        -webkit-text-size-adjust: 100%;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeSpeed;
        color: #000;
        font-family: "Inter", Helvetica, sans-serif;
        text-align: center;
        box-sizing: inherit;
        font-weight: 400;
        font-size: 1.25rem;
        line-height: 2.125rem;
    }
    
    section.hero.padding.hero-media>div>div.align-center.max-width-sm.space-bottom.space-hero.space-top>ul {
        -webkit-text-size-adjust: 100%;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeSpeed;
        color: #000;
        font-family: "Inter", Helvetica, sans-serif;
        text-align: center;
        box-sizing: inherit;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
        margin: 0 0 1rem 0;
        margin-top: 2rem;
        margin-bottom: 0;
        padding-left: 0;
    }
    
    section.hero.padding.hero-media>div>div.align-center.max-width-sm.space-bottom.space-hero.space-top>ul>li {
        -webkit-text-size-adjust: 100%;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeSpeed;
        color: #000;
        font-family: "Inter", Helvetica, sans-serif;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
        box-sizing: inherit;
        display: inline-block;
        padding-right: 1rem;
        vertical-align: top;
        min-width: 200px;
        border-radius: 4px;
    }
    
    section.hero.padding.hero-media>div>div.align-center.max-width-sm.space-bottom.space-hero.space-top>ul>li>a {
        -webkit-text-size-adjust: 100%;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeSpeed;
        font-family: "Inter", Helvetica, sans-serif;
        box-sizing: inherit;
        -webkit-tap-highlight-color: transparent;
        width: 100% !important;
        font-size: 1rem;
        transition: all .1s;
        border: none;
        cursor: pointer;
        display: inline-block;
        font-weight: 600;
        height: 3rem;
        line-height: 3rem;
        padding: 0 1.25rem;
        text-align: center;
        text-decoration: none !important;
        border-radius: 0.125rem;
        background: rgba(0, 0, 0, 0.1);
        color: #000;
        opacity: 1;
    }
    
    section.hero.padding.hero-media>div>div.align-center.max-width-sm.space-bottom.space-hero.space-top>ul>li:nth-child(2)>a {
        background: linear-gradient(96deg, #5D885C -11.92%, #87C186 51.6%, #548F52 114%);
        color: white;
    }
    
    .section-2 {
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding: 0 40px;
        align-items: center;
    }
    
    .section-2-title {
        text-align: center;
    }
    
    .fundraiser-item {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        cursor: pointer;
    }
    
    .fundraiser-item img {
        width: 100%;
        height: 100%;
        max-height: 250px;
        border-radius: 4px;
        object-fit: cover;
    }
    
    .fundraiser-item-title {
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 2rem;
        color: #000;
    }
    
    .current-campaigns-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 20px;
        margin-inline: -100px;
    }
.fundraiser-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 2rem;
    max-width: min(90vw, 1200px);
    margin: 0 auto;
}

.fundraiser-container .title-container {
    min-width: max(70vw, 25%);
    font-size: 38px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: -0.800000011920929px;
    text-align: left;
}

.fundraiser-container-content {
    min-width: max(70vw, 25%);
    min-height: 60vh;
    /* border: 1px solid #ccc; */
    margin: 0 auto;
    /* padding: 1rem; */
    display: flex;
    align-items: flex-start;
    gap: 2rem;
}

.content-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    height: 100%;
    position: relative;
    min-height: 10vh;
}

.content-right {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid #ccc;
    padding: 1rem;
    border-radius: 10px;
    max-width: 20rem;
    box-shadow: 0px 5px 16px -3px #00000033;
    height: 100%;
    min-width: 18rem;
}

.content-right h3 {
    font-family: Inter;
    font-size: 23px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.photoUpload {
    /* width: 100%; */
    min-height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #64846321;
    border-radius: 10px;
}

.createQuestionButton {
    /* position: absolute; */
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #648463;
    border: 1px solid #648463;
    border-radius: 20px;
    padding: 0.5rem;
    cursor: pointer;
    width: 15rem;
}

.question-answer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    border-radius: 10px;
    border: 1px solid #ccc;
    box-shadow: 0px 5px 16px -3px #00000033;
}

.fundraiser-title {
    font-family: Inter;
    font-size: 26px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    width: 100%;
}

.fundraiser-split-container {
    display: flex;
    width: 100%;
    gap: 40px;
}

.fundraiser-split-right {
    max-width: 300px;
    width: 30%;
    opacity: 0;
    animation: fadeIn 0.5s ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: .5s;
}

.fundraiser-split-left {
    display: flex;
    flex-direction: column;
    gap: 14px;
    flex: 1;
    animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.fundraiser-image {
    width: 100%;
    max-height: 400px;
    border-radius: 10px;
    overflow: hidden;
    aspect-ratio: 730/411;
}

.fundraiser-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    aspect-ratio: 730/411;
}

.fundraiser-team-description {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    width: 100%;
}

.border-bottom {
    border-bottom: 1px solid #ccc;
    padding-bottom: 14px;
}

.fundraiser-qna {
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 5px 16px -3px rgba(0, 0, 0, 0.20);
    padding-block: 10px 20px;
}

.qna-title {
    padding: 12px;
    font-size: 20px;
}

.qna-question {
    display: flex;
    flex-direction: column;
}

.qna-question .qna-item {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-top: 1px solid #ccc;
}

.post-qna-items {
    display: flex;
    gap: 10px;
}

.post-qna-items .post-qna-item {
    flex: 1;
}

.fundraising-team-desc-2 {
    display: flex;
    gap: 10px;
    align-items: center;
}

.donations-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.donations-list-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.donations-list-subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #767676;
}

.donations-list-item {
    display: flex;
    gap: 16px;
    padding: 12px;
    align-items: center;
}

.donations-list-item-description {
    display: flex;
    flex-direction: column;
}

.donations-list-item-name {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.fundraiser-right-raised {
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 5px 16px -3px rgba(0, 0, 0, 0.20);
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: sticky;
    top: 10px;
}

.right-donations-container {
    top: initial;
    margin-top: 10px;
}

.fundraiser-progress {
    height: 4px;
    width: 100%;
    border-radius: 2px;
    background: #E6F6EF;
    position: relative;
}

.fundraiser-progress-bar {
    height: 4px;
    border-radius: 2px;
    background: #87C186;
    /* background: linear-gradient(96deg, #5D885C -11.92%, #87C186 51.6%, #548F52 114%); */
    position: absolute;
    width: 0;
    transition: width 0.5s ease-in-out;
}

.fundraiser-action-buttons {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.fundraiser-price {
    color: #767676;
    font-size: 13.344px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    /* 157.377% */
}

.raised-amount {
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: black;
}

.fundraising-tiers {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.fundraising-tiers-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.fundraising-tiers-wrapper {
    display: flex;
    gap: 10px;
}

.fundraising-tiers-item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 12px;
    border-radius: 12px;
    background: linear-gradient(126deg, #FFFFFF -51.92%, #FFFFFF 51.6%, #FFFFFF 154%);
    height: 300px;
    flex: 1;
    transition: .3s all ease-in-out;
    /* make a ccc border with box shadow */
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
}

.fundraising-tiers-item:hover {
    cursor: pointer;
    border-color: transparent;
    box-shadow: 0px 5px 16px -3px rgba(0, 0, 0, 0.20);
}

.fundraising-tiers-item-price {
    font-size: 40px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fundraising-tiers-item-description {
    font-size: 20px;
    font-weight: 400;
}

.fundraising-tiers-item:hover {
    background: linear-gradient(126deg, #5D885C -51.92%, #87C186 51.6%, #548F52 154%);
    color: white;
}

.donate-input-container {
    display: flex;
    gap: 8px;
}

.amount-descriptor-container {
    display: flex;
    gap: 18px;
    align-items: center;
}

.amount-descriptor-container>*:first-child {
    position: relative;
}

.amount-descriptor-container>*:first-child::after {
    content: "";
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #87C186;
    display: block;
    position: absolute;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
}

.contact-us-qna-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 10px;
}

.contact-qna-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.qna-item .qna-question-answer {
    max-height: 0px;
    font-size: 16px;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    padding-top: 10px;
}

.qna-item.active .qna-question-answer {
    max-height: 1000px;
}

.qna-item .qna-question-question {
    font-size: 16px;
    position: relative;
    cursor: pointer;
    margin-bottom: -10px;
}

.qna-item {
    cursor: pointer;
    transition: .3s all ease-in-out;
}

.qna-item:not(.active):hover {
    background-color: #e1e1e1;
}

.qna-item.active .qna-question-question {
    font-weight: 600;
    margin-bottom: 0px;
}

.donation-percentage-container {
    display: flex;
    justify-content: space-between;
}

.donation-percentage {
    font-weight: 400;
}

.qna-item .qna-question-question::after {
    content: "";
    width: 0;
    height: 0;
    border-top: 5px solid #87C186;
    border-bottom: 5px solid transparent;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    transform-origin: top;
    transition: transform 0.4s ease-in-out;
}

.qna-item.active .qna-question-question::after {
    transform: translateY(-50%) rotate(180deg);
}

.qna-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.new-donation-tier-wrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.new-donation-tier-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    padding: 8px;
}

.new-donation-tier-list {
    display: flex;
    flex-direction: column;
}

.new-donation-tier-item {
    display: flex;
    gap: 10px;
    padding: 8px;
    align-items: center;
    border-top: 1px solid #ccc;
    background: linear-gradient(90deg, white 0, white 50%, #5D885C 50%, #87C186 50%, #548F52 114%, white 0) var(--_p, 200%)/200% no-repeat;
    color: #000000;
    transition: .4s;
}

.new-donation-tier-item:hover {
    cursor: pointer;
    color: #fff;
    --_p: 100%;
}

.new-donation-tier-item.active {
    --_p: 100%;
    color: #fff;
}

.donation-tier-item-title-wrapper {
    display: flex;
    flex-direction: row;
    gap: 4px;
    font-size: 16px;
    font-weight: 500;
}

.donation-tier-item-description {
    font-size: 14px;
    font-weight: 300;
}

@media (max-width:1000px) {
    .fundraiser-split-container {
        flex-direction: column-reverse;
    }
    .fundraiser-split-right {
        width: 100%;
        max-width: 100%;
    }
    .fundraiser-split-left {
        width: 100%;
    }
    .fundraiser-image {
        max-height: 300px;
    }
    .fundraiser-container-content {
        flex-direction: column;
    }
    .fundraiser-right-content {
        max-width: 100%;
    }
    .fundraiser-right-raised {
        position: relative;
        top: 0px;
    }
    .fundraising-tiers-wrapper {
        flex-direction: column;
    }
    .footer {
        overflow: hidden;
    }
    .all-footers {
        flex-direction: column;
    }
    .trademarks {
        display: none !important;
        line-break: anywhere;
    }
    .copyrights {
        display: flex;
        flex-direction: column;
    }
}